import React, { useEffect, useState } from "react";
import { Margin, usePDF } from "react-to-pdf";
import Seo from "../assets/components/seo";
import {
  Button,
  Chip,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Fab,
  useTheme,
} from "@mui/material";
import { AccessTimeOutlined } from "@mui/icons-material";
import { app } from "../utils/server/firebase";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";
import moment from "moment";
import "moment/locale/es-mx";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const db = getFirestore(app);

const Programa = () => {
  const theme = useTheme();
  const [programa, setPrograma] = useState([]);
  const [categorySelected, setCategorySelected] = useState("14 de noviembre");
  const [loading, setLoading] = useState(true); // New loading state
  const { t } = useTranslation();
  const fechas = [
    "14 de noviembre",
    "15 de noviembre",
    "16 de noviembre",
    "17 de noviembre",
  ];

  const formatDate = (fecha) => {
    const dia = moment(fecha).format("DD");
    const mes = moment(fecha).format("MMMM");
    return `${dia} de ${mes}`;
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "programa"), (querySnapshot) => {
      const result = [];
      querySnapshot.forEach((doc) => {
        const activity = doc.data();
        activity.id = doc.id;
        result.push({ ...activity });
      });
      setPrograma(result);
      setLoading(false); // Set loading to false when data is fetched
    });
    return unsub;
  }, []);

  const { toPDF, targetRef } = usePDF({
    filename: "programa.pdf",
    page: {
      margin: Margin.MEDIUM,
      format: "A4",
    },
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
      }}
    >
      <Seo
        title="Programa - Festival del Chocolate"
        description="Conoce el programa de actividades del 12vo Festival del Chocolate"
      />
      <Navbar />

      <Grid
        container
        justifyContent="center"
        paddingY={12}
        minHeight="60vh"
        spacing={4}
      >
        <Grid item container maxWidth={1400}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                color="white"
                position="absolute"
                textAlign="center"
              >
                {t("titulo")}
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          marginTop={2}
          spacing={2}
        >
          {fechas.map((tag, index) => (
            <Grid item key={index}>
              <Chip
                label={tag}
                size="medium"
                variant={categorySelected === tag ? "filled" : "outlined"}
                style={{
                  backgroundColor:
                    categorySelected === tag
                      ? theme.palette.primary.main
                      : undefined,
                  color: categorySelected === tag ? "white" : undefined,
                }}
                onClick={() => setCategorySelected(tag)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button
            variant="contained"
            onClick={toPDF}
            sx={{
              bgcolor: "#a57a3e",
              color: "white",
              "&:hover": {
                backgroundColor: "#a57a3e",
              },
            }}
          >
            Descarga el programa del día
          </Button>
        </Grid>
        <Grid
          item
          container
          maxWidth="md"
          ref={targetRef}
          marginTop={1}
          marginX={2}
        >
          {loading ? ( // Conditional rendering based on loading state
            <Grid item xs={12} textAlign="center">
              <CircularProgress size={64} />
              <Typography fontSize={32} sx={{ margin: 2 }}>
                Cargando el programa...
              </Typography>
            </Grid>
          ) : (
            programa
              .filter((item) => {
                return (
                  formatDate(item.inicio.toDate()) === categorySelected &&
                  !item.disabled &&
                  item.inicio.toDate().getFullYear() === 2024
                );
              })
              .sort((a, b) => {
                return a.inicio - b.inicio;
              })
              .map((item, key) => (
                <Grid
                  item
                  container
                  bgcolor={
                    item.salon === "Chocolate" ||
                    item.salon === "Tabá Cao" ||
                    item.salon === "Cacao" ||
                    item.salon === "Cavacao"
                      ? theme.palette.primary.main
                      : item.salon === "Sabor a tabasco"
                      ? "#cc8400"
                      : item.salon === "Origen Experiencia Multisensorial"
                      ? "#9D2449"
                      : item.salon === "Plaza del Pueblo"
                      ? "#7d1511"
                      : null
                  }
                  justifyContent="center"
                  key={key}
                  borderRadius={2}
                  mb={1}
                  p={1}
                >
                  <Grid
                    item
                    container
                    xs={3}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    p={1}
                  >
                    <Grid item>
                      <AccessTimeOutlined htmlColor="white" fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="white"
                        textAlign="center"
                        variant="h6"
                        whiteSpace="pre-wrap"
                      >
                        {moment(item.inicio.toDate()).format("LT")} -{" "}
                        {moment(item.fin.toDate()).format("LT")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    direction="column"
                    xs={9}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="white"
                        variant="h5"
                        whiteSpace="pre-wrap"
                      >
                        {item.nombre}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="white" variant="body1">
                        {item?.ponente}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        textAlign="end"
                        color="white"
                        variant="subtitle1"
                      >
                        {item.salon === "Chocolate"
                          ? "Salón Chocolate Nave Chocolate"
                          : item.salon === "Tabá Cao" || item.salon === "Cacao"
                          ? "Salón Cacao Nave Chocolate"
                          : item.salon === "Cavacao"
                          ? "Salón Cavacao Nave Chocolate"
                          : item.salon === "Sabor a tabasco"
                          ? "Sabor a Tabasco Área Gastronómica"
                          : item.salon === "Área Infantil"
                          ? "Área Infantil Nave Cacao"
                          : item.salon}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
          )}
          <Grid item xs={12}>
            <Typography variant="caption">{t("nota")}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Footer />

      <Fab
        variant="extended"
        color="primary"
        href="/reservaciones"
        sx={{
          margin: 0,
          top: "auto",
          left: { xs: 20, sm: "auto" },
          bottom: 20,
          right: 20,
          position: "fixed",
          bgcolor: "#a57a3e",
          color: "white",
          "&:hover": {
            backgroundColor: "#a57a3e",
          },
        }}
      >
        {t("catas")}
      </Fab>
    </Container>
  );
};

export default Programa;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["programa"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
